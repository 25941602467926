.pac-container {
    z-index: 1100;
  }
  
  .map-search-box-input {
    width: 100%;
    height: 40px;
    padding-inline-start: 15px;
    z-index: 1;
    border: 0;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  }
  
  .map-search-box-input:focus {
    outline: 0;
  }
  