@import "variables";

.zIndex-2{
    z-index: 2
}
.rs-nav-item.rs-nav-item-active{
 color:$gray-900 !important;
 font-weight: 600;
}
.rs-nav-subtle .rs-nav-item.rs-nav-item-active:before{
 background-color: $base !important
}
.bg-primary{
    background-color: $base !important;
}

.search-label{
    margin-bottom: 0px !important;
}
.breadcrumb-item{
    font-size: 11px !important;
}
.table-header-colored{
    th{
        background-color: #fbfaff;
    }
}

.data-table-pagination>.rs-pagination>.rs-pagination-btn.rs-pagination-btn-active{
    color:white ;
    background-color:$primary;
    border-color:$primary;
}
.data-table-pagination{
      margin-block:10px 5px;
}
.navbar-header{
    background-color: #fbfaff;
}


//INPUT
.textOnInput {
    position: relative;
    width: 100%;
}

.textOnInput> label {
    position: absolute;
    top: -13px;
    left: 10px;
    padding: 2px;
    z-index: 1001;
}
.textOnInput > label:after {
    content: " ";
    background-color:$white;
    width: 100%;
    height: 13px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}
.customlabel {
    font-size: 14px;
    font-weight: 500;
    color: $gray-600;
    display: inline-block;
    margin-bottom: .5rem;
}
.errorLabel {
    color: $danger !important;
}
.form2-control {
    box-shadow: none !important;
    padding: 0.7rem 0.75rem  0.4rem 0.75rem !important;
}
.defaultInput{
    width: 100%;
    font-size: 14px;
    color: $gray-800 !important;
}
.errorInput{
    border-color:$danger !important;
}
.defaultInput:hover,.defaultInput:focus{
    border-color:$gray-600    !important;

}
.defaultInput:focus  ~ .customlabel{
    color: $gray-700   !important;
}
.defaultInput:hover  ~ .customlabel{
    color:$gray-700  !important;
}
.errorInfo{
    font-size: 10px;
    color: $danger !important;
}



//SELECT PICKER INPUT
.rs-picker-menu{
    z-index: 1055 !important
}
.selectpickerInput{
    padding: 0.1rem 0.2rem  0.2rem 0.1rem !important;
    .rs-picker-toggle-value{
        color: $gray-800 !important;
        font-size: 14px;
    }
    .rs-picker-toggle-placeholder{
        font-size: 14px;
    }
    .rs-picker-toggle-active, .rs-picker.rs-picker-focused{

        box-shadow: none !important;
    }

}

.selectpickerInput>div{
    border: 0px !important;
}
.selectpickerInput>div:focus-visible{
    outline: 0px !important;
}



//GRID DELETE ALERT
.cancel-button{
    background-color: $danger !important;
    border-color:$danger  !important;
    box-shadow:unset !important
    }
    .confirm-button{
    background-color: $success  !important;
    border-color:  $success  !important;
    box-shadow:unset !important
    }



    //MODAL CENTER

    .centeredModal{
        .rs-modal-dialog{
            transform: translate(0%, 100%);
        }
    }



    //TICKET SUBJECT LIST
    .ticketContainerHeight {
        height: calc(100vh - 240px);
    }
    .ticketsubjectContainer{
        width: 100%;
        border-right: 1px solid $gray-300;
        .ticketsubjectContaineritem{
            margin-right: 10px;
            border: 1px solid $gray-300;
            padding: 10px 10px 10px 10px;
            margin-bottom: 5px;
            border-radius: 8px;
            cursor: pointer;
        }
        .ticketsubjectsearch{
            padding-top: 0px !important;
            margin-right: 10px;
        }
        .selectedItem{
            background-color: #f3f3f9;
        }
    }
    .ticketSubjectDepartmentFilter{
        width: 100%;
        margin-right: 10px;
        margin-bottom: 5px;
        .rs-picker-toggle-value{
            color: $gray-500 !important;
            font-size: 14px;
        }
        .rs-picker-toggle-placeholder{
            font-size: 14px;
        }
        .rs-picker-toggle-active, .rs-picker.rs-picker-focused{

            box-shadow: none !important;
        }
    }
    .ticketSubjectDepartmentFilter >div{

        border: 0px !important;
    }









//DATEPICKER
.customDatePicker{
    padding: 0.7rem 0.75rem 0.4rem 0.75rem !important;
    width: 100%;
}
.react-datepicker-popper{
    z-index: 2035 !important;
}
.react-datepicker__triangle{
    transform:translate(149px, 0px) !important;
}



.rs-picker-select-menu{
    max-height: 220px !important
}

.form-control:disabled, .form-control[readonly]{
    background-color: #fff !important
}




.customAvatar{
    width: 120px;
    height: 120px;
    border-radius: 8px;
}



/************************************/
/*CREDIT CARD*/
/************************************/



.creditcard{
    width: 250px;
    height: 145px;
      -webkit-perspective: 600px;
      -moz-perspective: 600px;
      perspective:600px;
      
    }
    
    .card__part{
      box-shadow: 1px 1px #aaa3a3;
        top: 0;
      position: absolute;
    z-index: 1000;
      left: 0;
      display: inline-block;
        width: 250px;
        height: 145px;
      //  background-image:  linear-gradient(to right bottom, #fd696b, #fa616e, #f65871, #f15075, #ec4879); /*linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a)*/
        background-image: linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 8px;
       
        -webkit-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -moz-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -ms-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -o-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
    }
    
    .card__front{
      padding: 10px;
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    }
    
    .card__back {
      padding: 18px 0;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    }
    
    .card__black-line {
        margin-top: 5px;
        height: 38px;
        background-color: #303030;
    }
    
    .card__logo {
        height: 16px;
    }
    
    .card__front-logo{
          position: absolute;
        top: 18px;
        right: 18px;
    }
    .card__square {
        border-radius: 5px;
        height: 30px;
    }
    
    .card_numer {
        display: block;
        width: 100%;
        word-spacing: 10px;
        font-size: 16px;
        letter-spacing: 2px;
        color: #fff;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    
    .card__space-65 {
        width: 73%;
        float: left;
    }
    
    .card__space-35{
        width: 23%;
        float: left;
    }
    
    .card__label {
        font-size:8px;
        white-space: nowrap;
        text-transform: uppercase;
        color: rgba(255,255,255,0.8);
        letter-spacing: 1px;
    }
    
    .card__info {
        margin-bottom: 0;
        margin-top: 5px;
        font-size: 12px;
        line-height: 18px;
        color: #fff;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    
    .card__back-content {
        padding: 15px 15px 0;
    }
    .card__secret--last {
        color: #303030;
        text-align: right;
        margin: 0;
        font-size: 12px;
    }
    
    .card__secret {
        padding: 5px 12px;
        background-color: #fff;
        position:relative;
    }
    
    .card__secret:before{
      content:'';
      position: absolute;
    top: -3px;
    left: -3px;
    height: calc(100% + 6px);
    width: calc(100% - 42px);
    border-radius: 4px;
      background: repeating-linear-gradient(45deg, #ededed, #ededed 5px, #f9f9f9 5px, #f9f9f9 10px);
    }
    
    .card__back-logo {
        position: absolute;
        bottom: 15px;
        right: 15px;
    }
    
    .card__back-square {
        position: absolute;
        bottom: 15px;
        left: 15px;
    }
    
    .creditcard:hover .card__front {
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
    
    }
    
    .creditcard:hover .card__back {
        -webkit-transform: rotateY(0deg);
        -moz-transform: rotateY(0deg);
    }
/************************************/
/*CREDIT CARD*/
/************************************/